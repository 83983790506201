import {
  createRouter,
  createWebHashHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
  RouterScrollBehavior
} from 'vue-router'
import { useAppViewStore } from '@/stores/appView'
import { useFormStore } from '@/stores/form'
import { useNavigationStore } from '@/stores/navigation'
import { useProgressStore } from '@/stores/progress'
import { useRecordNavigationStore } from '@/stores/recordNavigation'
import { usePendingRouteStore } from '@/stores/pendingRoute'

import { MODULE_AUFGABEN_COLOR } from '@/modules/moduleConstants'
import AdministrationRoutes from '@/router/modules/administrationRoutes'
import ArbeitsmittelRoutes from '@/router/modules/arbeitsmittelRoutes'
import AufgabenRoutes from '@/router/modules/aufgabenRoutes'
import BerichteRoutes from '@/router/modules/berichteRoutes'
import EmailingRoutes from '@/router/modules/emailingRoutes'
import KompetenzenRoutes from '@/router/modules/kompetenzenRoutes'
import KonsoleRoutes from '@/router/modules/konsoleRoutes'
import KostenRoutes from '@/router/modules/kostenRoutes'
import SchulungsorganisationRoutes from '@/router/modules/schulungsorganisationRoutes'
import LabelsRoutes from '@/router/modules/labelsRoutes'
import LiegenschaftenRoutes from '@/router/modules/liegenschaftenRoutes'
import ManagementRoutes from '@/router/modules/managementRoutes'
import MitarbeiterRoutes from '@/router/modules/mitarbeiterRoutes'
import NotizenRoutes from '@/router/modules/notizenRoutes'
import OrganisationRoutes from '@/router/modules/organisationRoutes'
import PraeventionRoutes from '@/router/modules/praeventionRoutes'
import ProjekteRoutes from '@/router/modules/projekteRoutes'
import StammdatenRoutes from '@/router/modules/stammdatenRoutes'
import TicketsystemRoutes from '@/router/modules/ticketsystemRoutes'
import VerwaltungRoutes from '@/router/modules/verwaltungRoutes'

const BaseLogin = () => import('@/base/BaseLogin.vue')
const BaseLogout = () => import('@/base/BaseLogout.vue')
const BasePageFreigabeNotFound = () => import('@/base/BasePageFreigabeNotFound.vue')
const BasePageNotFound = () => import('@/base/BasePageNotFound.vue')
const BasePermissionDenied = () => import('@/base/BasePermissionDenied.vue')
const BaseAppLayout = () => import('@/base/BaseAppLayout.vue')
const Dashboard = () => import('@/modules/dashboard/Dashboard.vue')
const Importvorlagen = () => import('@/modules/administration/Importvorlagen.vue')
const PasswortReset = () => import('@/modules/verwaltung/PasswortReset.vue')
const AusbildungsnachweiseOeffentlich = () => import('@/modules/mitarbeiter/AusbildungsnachweiseOeffentlich.vue')
const ProjektUnterlagenOeffentlich = () => import('@/modules/projekte/ProjektUnterlagenOeffentlich.vue')
const ArbeitsmittelUnterlagenOeffentlich = () => import('@/modules/arbeitsmittel/ArbeitsmittelUnterlagenOeffentlich.vue')
const GefahrstoffUnterlagenOeffentlich = () => import('@/modules/praevention/GefahrstoffUnterlagenOeffentlich.vue')
const OeffentlicheKurse = () => import('@/modules/schulungsorganisation/OeffentlicheKurse.vue')
const OeffentlicheKurseAnmeldung = () => import('@/modules/schulungsorganisation/OeffentlicheKurseAnmeldung.vue')
const OeffentlicheVisitenAnmeldung = () => import('@/modules/mitarbeiter/OeffentlicheVisitenAnmeldung.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/login',
    name: 'BaseAppLayout',
    component: BaseAppLayout,
    children: [
      {
       path: '/permissiondenied',
       name: 'PermissionDenied',
       component: BasePermissionDenied
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { icon: 'mdi-home-outline', color: MODULE_AUFGABEN_COLOR }
      },
      {
        path: '/dashboards/dashboards/:id',
        name: 'DashboardDetail',
        component: Dashboard,
        props: updateFormProps,
        meta: { icon: 'mdi-home-outline', color: MODULE_AUFGABEN_COLOR }
      },
      { path: '/importvorlagen', name: 'Importvorlagen', component: Importvorlagen },

      ...AdministrationRoutes,
      ...ArbeitsmittelRoutes,
      ...AufgabenRoutes,
      ...BerichteRoutes,
      ...EmailingRoutes,
      ...KompetenzenRoutes,
      ...KonsoleRoutes,
      ...KostenRoutes,
      ...SchulungsorganisationRoutes,
      ...LabelsRoutes,
      ...LiegenschaftenRoutes,
      ...ManagementRoutes,
      ...MitarbeiterRoutes,
      ...NotizenRoutes,
      ...OrganisationRoutes,
      ...PraeventionRoutes,
      ...ProjekteRoutes,
      ...StammdatenRoutes,
      ...TicketsystemRoutes,
      ...VerwaltungRoutes,
    ],
  },
  {
    path: '/verwaltung/reset/:token',
    name: 'PasswortReset',
    component: PasswortReset,
    props: true,
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: BaseLogin,
    meta: { requiresAuth: false }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: BaseLogout
  },
  {
    path: '/freigabe404',
    name: 'FreigabeNotFound',
    component: BasePageFreigabeNotFound,
    meta: { requiresAuth: false }
  },
  // Ausbildungsdokumente oeffentlich
  {
   path: '/ausbildungsnachweiseoeffentlich/:hash/',
   name: 'AusbildungsnachweiseOeffentlich',
   component: AusbildungsnachweiseOeffentlich,
   props: true,
   meta: { requiresAuth: false }
  },
  // Projektunterlagen oeffentlich
  {
   path: '/projektunterlagenoeffentlich/:hash/',
   name: 'ProjektUnterlagenOeffentlich',
   component: ProjektUnterlagenOeffentlich,
   props: true,
   meta: { requiresAuth: false }
  },
  // Arbeitsmittelunterlagen oeffentlich
  {
   path: '/arbeitsmittel/arbeitsmittel/:id/arbeitsmittel_unterlagen_oeffentlich',
   name: 'ArbeitsmittelUnterlagenOeffentlich',
   component: ArbeitsmittelUnterlagenOeffentlich,
   props: true,
   meta: { requiresAuth: false }
  },
  // Gefahrstoffunterlagen oeffentlich
  {
   path: '/praevention/gefahrstoffe/:id/gefahrstoff_unterlagen_oeffentlich',
   name: 'GefahrstoffUnterlagenOeffentlich',
   component: GefahrstoffUnterlagenOeffentlich,
   props: true,
   meta: { requiresAuth: false }
  },
  {
    path: '/visitenanmeldung/:hash/',
    name: 'OeffentlicheVisitenAnmeldung',
    props: true,
    component: OeffentlicheVisitenAnmeldung,
    meta: { requiresAuth: false }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: BasePageNotFound
  },
  // Öffentliche Schulungen Seite
  {
    path: '/schulungsorganisation/oeffentlicheschulungen/',
    name: 'OeffentlicheKurse',
    component: OeffentlicheKurse,
    meta: { requiresAuth: false }
  },
  {
    path: '/schulungsorganisation/oeffentlicheschulungen/:typ/:id',
    name: 'OeffentlicheKurseAnmeldung',
    component: OeffentlicheKurseAnmeldung,
    props: true,
    meta: { requiresAuth: false }
  },
]
// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior: RouterScrollBehavior = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  savedPosition: ScrollToOptions | null
) => {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    // console.log('return saved position')
    const recordNavigationStore = useRecordNavigationStore()

    // Vor/Zurück Positionen wiederherstellen
    // z.b. bei zuruck von der BilderCard Form
    if (to && to.path) {
      recordNavigationStore.loadPreviousRecord(to.path)
    }

    // debugger
    return savedPosition
  } else {
    const position: ScrollToOptions = {}

    // scroll to anchor by returning the selector
    if (to.hash) {

      const hashes = ['#dokumente', '#bilder', '#aufgaben']
      if (hashes.includes(to.hash)) {
        position.top = 150
      }

      if (document.querySelector(to.hash)) {
        return position
      }

      // fallback is scroll to top
      position.left = 0
      position.top = 0
      return position
      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      // console.log('return false')
      // return false
    }

    return new Promise(() => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollToTop)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.left = 0
        position.top = 0
      }
    })
  }
}

const router = createRouter({
  history: createWebHashHistory(import.meta.env.NODE_ENV === 'production' ? (import.meta.env.VITE_APP_URL_PREPEND || '/') : '/'),
  scrollBehavior: scrollBehavior,
  routes: routes
})

router.beforeEach((to, from, next) => {
  let requiresAuth = true
  if (to.meta && 'requiresAuth' in to.meta) {
    requiresAuth = to.meta.requiresAuth as boolean
  }
  if (requiresAuth) {
    if (!localStorage.getItem('token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath, token: to.query.token }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  let pendingRouteStore: ReturnType<typeof usePendingRouteStore> | undefined
  let formStore: ReturnType<typeof useFormStore> | undefined
  try {
    formStore = useFormStore()
    pendingRouteStore = usePendingRouteStore()
  } catch {
    //Exception ist okay, siehe Kommentar unten
  }

  // Wenn noch Dokumente beim Hochladen sind blockieren
  if (formStore && formStore.isDocumentUploadInProgress) {
    // console.debug(`Blockiere Navigation nach ${to.path} wegen Dokumentenupload`)
    pendingRouteStore?.setPendingRoute(to)
    formStore.showDocumentUploadInProgressDialog()
    next(false)
    return
  }
  if (formStore && formStore.hasChangedData) {
    // console.debug(`Blockiere Navigation nach ${to.path} wegen ungespeicherten Daten`)
    pendingRouteStore?.setPendingRoute(to)
    formStore.showUnsavedWarning()
    next(false)
    return
  }

  try {
    //AppView zurücksetzen
    const appViewStore = useAppViewStore()
    appViewStore.setShowFloatButton(false)
    appViewStore.resetSearchCardQueries()

    // Navigation zurücksetzen
    const navigationStore = useNavigationStore()
    navigationStore.clearOptionalNavigationItems()
    const progressStore = useProgressStore()
    progressStore.finish()
  } catch {
    /*
      Diese Exception ist okay.
      Beim allerersten Seitenaufruf ist Pinia noch nicht installiert und deswegen crasht useNavigationStore()
      Da zu diesem Zeitpunkt aber noch keine NavigationItems gesetzt sind, ist das okay
     */
    // console.debug(e)
  }
  next()
  return

})

router.afterEach((to, from, failure) => {
  if (!failure) {
    // console.debug('reset Pending Route nach erfolgreicher Navigation')
    const pendingRouteStore = usePendingRouteStore()
    pendingRouteStore.resetPendingRoute()
  }
  sessionStorage.setItem('browser_history_available', String(true))
})

// Native Browser-Warnung anzeigen und aufräumen, wenn der Benutzer die Seite verlässt
window.addEventListener('beforeunload', function (event) {
  const appViewStore = useAppViewStore()
  const formStore = useFormStore()
  if (formStore.isDocumentUploadInProgress) {
    event.returnValue = 'nananana'
  }
  appViewStore.resetSearchCardQueries()
  appViewStore.setShowFloatButton(false)
  if (formStore.hasChangedData) {
    event.returnValue = 'nananana'
  }
})

export default router
