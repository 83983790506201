import { defineStore } from 'pinia'
import api from '@/utils/api'

type NavigationRecord = {
  uniqueViewName: string
  ids: number[]
  nextUrl: string | null
  previousUrl: string | null
}

export type RecordNavigationState = NavigationRecord & {
  hide: boolean
  previousRecord: Record<string, NavigationRecord>
}

export const useRecordNavigationStore = defineStore({
  id: 'recordNavigation',
  state: (): RecordNavigationState => ({
    hide: true,
    uniqueViewName: '',
    ids: [],
    nextUrl: null,
    previousUrl: null,
    previousRecord: {}
  }),
  actions: {
    setHidden(payload: boolean) {
      this.hide = payload
    },
    setUniqueViewName(payload: string) {
      this.uniqueViewName = payload
    },
    setIds(payload: number[]) {
      this.ids = payload
    },
    setNextUrl(payload: string) {
      this.nextUrl = payload
    },
    setPreviousUrl(payload: string) {
      this.previousUrl = payload
    },
    saveCurrentRecord(payload: string) {
      this.previousRecord[payload] = {
        previousUrl: this.previousUrl,
        nextUrl: this.nextUrl,
        uniqueViewName: this.uniqueViewName,
        ids: this.ids,
      }
    },
    loadPreviousRecord(payload: string) {
      if (payload && this.previousRecord[payload]) {
        const previousRecord = this.previousRecord[payload]
        this.previousUrl = previousRecord.previousUrl
        this.nextUrl = previousRecord.nextUrl
        this.uniqueViewName = previousRecord.uniqueViewName
        this.ids = previousRecord.ids
      }
    },
    async loadNextUrl() {
      const nextUrl = this.nextUrl
      if (nextUrl) {
        const data = await api.get(nextUrl, {}, true, true, false)
        if (data?.results) {
          const ids: number[] = this.ids.slice(0)
          for (const record of data.results) {
            if (record.id) {
              ids.push(record.id)
            }
          }
          this.ids = ids
        }
        if(data) {
          this.nextUrl = data.next
        }
      }
    },
    async loadPreviousUrl() {
      const previousUrl = this.previousUrl
      if(previousUrl) {
        const data = await api.get(previousUrl, {}, true, true, false)
        if (data?.results) {
          const ids: number[] = this.ids.slice(0)
          for (const record of data.results.reverse()) {
            if (record.id) {
              ids.unshift(record.id)
            }
          }
          this.ids = ids
        }
        if(data) {
          this.previousUrl = data.previous
        }
      }
    }
  },
  getters: {
  }
})